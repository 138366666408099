
import { Component, Vue, Prop } from 'vue-property-decorator';


@Component({
  components: {
  }
})
export default class Card extends Vue {
  @Prop({default: false}) clickable: boolean;
}
