
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FRadio from "@/components/f/Radio.vue";

@Component({
  components: {
    LItem,
    FRadio,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'single_child';
    this._need = true;
    this._popup_title = 'single_child';
    this._popup_content = 'popup.single_child';
  }

  get condition() {
    return this.object.sex == 0;
  }

  get is_change() {
    return this.object.widow_type !== this.old_object.widow_type;
  }
}
