
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import EmployeejoinEmployeeinfoDatefree from "@/components/employeejoin/employeeinfo/datefree.vue";
import EmployeejoinEmployeeinfoFreefree from "@/components/employeejoin/employeeinfo/freefree.vue";
import EmployeejoinEmployeeinfoFree from "@/components/employeejoin/employeeinfo/free.vue";
import EmployeejoinEmployeeinfoQanda from "@/components/employeejoin/employeeinfo/qanda.vue";

@Component({
  components: {
    EmployeejoinEmployeeinfoDatefree,
    EmployeejoinEmployeeinfoFreefree,
    EmployeejoinEmployeeinfoFree,
    EmployeejoinEmployeeinfoQanda,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() employee_info: any;
  @Prop() value: any;

  get tmp_each_employee_infos() {
    return this.value;
  }
  set tmp_each_employee_infos(val) {
    this.$emit('input', val);
  }
}
