
import { Inject } from '@/library/provide';
import mixinPage from '@/mixins/mixinPage';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import LItemlevel from "@/components/l/Itemlevel.vue";
import FTextarea from "@/components/f/Textarea.vue";

@Component({
  components: {
    LItemlevel,
    FTextarea,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() employee_info: any;
  @Prop() value: any;

  created() {
    this.input_each_employee_info();
  }
  
  get raw_object() {
    return this.value;
  }
  get tmp_object() {
    return this.value
  }
  set tmp_object(val) {
    this.$emit('input', val);
  }

  input_each_employee_info() {
    let last_index = this.object.length - 1;
    if (last_index == -1) {
      this.object.push({content: null});
    }
  }
}
