
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import EmployeejoinEmployeeinfoImage from "@/components/employeejoin/employeeinfo/image.vue";

@Component({
  components: {
    EmployeejoinEmployeeinfoImage,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() employee_info: any;
  @Prop() value: any;

  get tmp_each_employee_infos() {
    return this.value;
  }
  set tmp_each_employee_infos(val) {
    this.$emit('input', val);
  }
}
