
import { Component, Mixins } from 'vue-property-decorator';
import mixinPart from '@/mixins/mixinPart';
import LItem from "@/components/l/Item.vue";
import FText from "@/components/f/Text.vue";

@Component({
  components: {
    LItem,
    FText,
  }
})
export default class Main extends Mixins(mixinPart) {
  created() {
    this._title = 'remittance_to_spouce';
    this._need = true;
  }

  get condition() {
    return this.object.is_not_live == 1;
  }

  get is_change() {
    return this.object.not_live_money !== this.old_object.not_live_money;
  }
}
