
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinForm from '@/mixins/mixinForm';
import FIcon from "@/components/f/Icon.vue";
import FButton from "@/components/f/Button.vue";
import FPartsImg from "@/components/f/parts/Img.vue";


@Component({
  components: {
    FIcon,
    FButton,
    FPartsImg,
  }
})
export default class Text extends Mixins(mixinForm) {
  @Prop() width: number;//pxをnumberのみ
  @Prop() height: number;//pxをnumberのみ
  @Prop() edit_width: number;
  @Prop() edit_height: number;
  @Prop() initial_image?: string;//すでに画像がある場合のbase64
  @Prop() value?: string;//編集用の画像のbase64(object.face_imageのようなのをv-modelで渡す)
  @Prop({default: 4}) quality: number;
  @Prop() bg_icon: any;
  @Prop({default: true}) is_valid: boolean;
  @Prop({default: true}) is_update_directly: boolean;
  @Prop({default: true}) just: boolean;

  get use_edit_width() {
    return this.edit_width ?? this.width;
  }
  get use_edit_height() {
    return this.edit_height ?? this.height;
  }

  is_custom_display: boolean = false;

  get is_display() {
    return this.is_update_directly ? this.is_input : this.is_custom_display;
  }
  set is_display(val) {
    if (!val) {
      if (this.is_update_directly) this.$emit('cancel')
      else this.is_custom_display = false;
    }
  }

  
  get style() {
    return {
      '--width': this.width+'px',
      '--height': this.height+'px',
    };
  }

  updateImage(val) {
    this.$emit('input', val);
    if (this.is_update_directly) this.$emit('update');
    else this.is_custom_display = false;
  } 
  editImage() {
    if (this.is_update_directly) this.$emit('edit');
    else this.is_custom_display = true;
  }
}
