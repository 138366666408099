import { Component, Mixins } from 'vue-property-decorator';
import utilMixins from '@/mixins';
import vuex_data from '@/vuex/vuex_data';
import vuex_manager from '@/vuex/vuex_manager';
import { Provide } from '@/library/provide';
import * as util from '@/util';

@Component
export default class mixinJoin extends Mixins(utilMixins) {
  get m() {
    return vuex_manager.employee_demand_manager;
  }
  get global() {
    return vuex_data.employee_demand_global.get(this);
  }
  get employeely() {
    return vuex_data.employee_each_employeely.get(this);
  }
  get info() {
    return vuex_data.employee_each_info.get(this);
  }
  get family() {
    return vuex_data.employee_each_family.get(this);
  }

  @Provide('employee_type')
  get employee_type() {
    return this.employeely.employee.type;
  }

  get employee_id() {
    return this.s_employee_id;
  }


  get step() {
    return this.m.state.step;
  }
  set step(val) {
    this.m.setState({name: 'step', val});
  }
  get step_hash() {
    const result = {};
    Object.keys(this.steps).forEach(key => {
      result[this.steps[key].index] = key;
    });
    return result;
  }
  get step_key() {
    return this.step_hash[this.step];
  }
  get steps() {
    const steps = {};
    let index = 1;

    if (this.employee_type != 1 && this.employee_type != 2) {
      steps['base'] = {index: index++, name: util.$t('base_info'), is_next: true, next_index: 'address'};
      steps['address'] = {index: index++, name: util.$t('address_info'), is_prev: true, is_next: true, next_index: this.employee_type != 5 ? 'bank' : 'tax', prev_index: 'base'};
      if (this.employee_type != 5) steps['bank'] = {index: index++, name: util.$t('bank_info'), is_prev: true, is_next: true, next_index: 'tax', prev_index: 'address'};
      steps['tax'] = {index: index++, name: util.$t('self_info'), is_prev: true, is_next: true, next_kou_index: 'spouce', next_otsu_index: this.global.employee_infos.length != 0 ? 'employee_info' : 'confirm', prev_index: this.employee_type != 5 ? 'bank' : 'address'};
      steps['spouce'] = {index: index++, name: util.$t('spouce_info'), is_prev: true, is_next: true, next_index: 'dependant', prev_index: 'tax'};
      steps['dependant'] = {index: index++, name: util.$t('dependant_info'), is_prev: true, is_next: true, next_index: this.global.employee_infos.length != 0 ? 'employee_info' : 'confirm', prev_index: 'spouce'};
      if (this.global.employee_infos.length != 0) steps['employee_info'] = {index: index++, name: util.$t('custom_info'), is_prev: true, is_next: true, next_index: 'confirm', prev_kou_index: 'dependant', prev_otsu_index: 'tax'};
      steps['confirm'] = {index: index++, name: util.$t('confirm'), is_prev: false, is_next: true, next: util.$t('demand_change_submit'), next_index: 'finish', prev_kou_index: this.global.employee_infos.length != 0 ? 'employee_info' : 'dependant', prev_otsu_index: this.global.employee_infos.length != 0 ? 'employee_info' : 'tax'};
    } else {
      steps['base'] = {index: index++, name: util.$t('base_info'), is_prev: true, is_next: true, next_index: this.global.employee_infos.length != 0 ? 'employee_info' : 'confirm', prev_index: 'initial'};
      if (this.global.employee_infos.length != 0) steps['employee_info'] = {index: index++, name: util.$t('custom_info'), is_prev: true, is_next: true, next_index: 'confirm', prev_kou_index: 'base', prev_otsu_index: 'base'};
      steps['confirm'] = {index: index++, name: util.$t('confirm'), is_prev: false, is_next: true, next: util.$t('demand_change_submit'), next_index: 'finish', prev_kou_index: this.global.employee_infos.length != 0 ? 'employee_info' : 'base', prev_otsu_index: this.global.employee_infos.length != 0 ? 'employee_info' : 'base'};
    }
    
    return steps;
  }
}