
import { Component, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import { Inject } from '@/library/provide';
import dialog from '@/vuex/dialog';
import EmployeeeachSelfFamilyDependantCounter from "@/components/employeeeach/self/family/dependant/Counter.vue";
import FButton from "@/components/f/Button.vue";
import LItemlevel from "@/components/l/Itemlevel.vue";
import EmployeejoinDependant from "@/components/employeejoin/Dependant.vue";

@Component({
  components: {
    EmployeeeachSelfFamilyDependantCounter,
    FButton,
    LItemlevel,
    EmployeejoinDependant,
  }
})
export default class Main extends Mixins(mixinPage) {
  addDependant() {
    this.manager.object.dependants.push({
      id: null,
      employee_id: this.tmp_employee.id,
      name: null, 
      kana: null, 
      birth_date: null, 
      mynum: null,
      dependant_relationship_id: null, 
      is_housemate: 1, 
      disability_type: 0, 
      income: 0, 
      retire_income: 0, 
      other_income: 0, 
      is_not_live: 0, 
      is_child_allowance: 0, 
      is_special_disability_housemate: 1,
      is_old_spouce_housemate: null,
      is_international_student: 0,
      address: null,
      disability_reason: null,
      not_live_money: null,
    });
    this.dependant_num = this.dependant_num + 1;
  }

  deleteDependant(index: number) {
    const delete_index = this.dependant_num > 1 ? index : '';
    dialog.openConfirmDialog({msg: this.$t('delete_confirm_join_dependant', {index: delete_index}) as string, cancel_button: this.$t('not_delete') as string, exec_button: this.$t('yes_delete') as string, func: this.deleteDependantProcess, args: {index: index - 1}});
  }
  deleteDependantProcess({index}: {index: number}) {
    this.dependant_num = this.dependant_num - 1;
    this.manager.object.dependants.splice(index, 1);
  }

  get tmp_employee() {
    return this.manager.object.employee;
  }
  set tmp_employee(val) {
    this.manager.object.employee = val;
  }

  get dependants() {
    return this.manager.object.dependants;
  }
  set dependants(val) {
    this.manager.object.dependants = val;
  }

  get dependant_num() {
    return this.manager.object.dependant_num;
  }
  set dependant_num(val) {
    this.manager.object.dependant_num = val;
  }
}
