
import { Component, Mixins, Prop } from 'vue-property-decorator';
import mixinPart from '@/mixins/mixinPart';
import LItem from "@/components/l/Item.vue";
import FButton from "@/components/f/Button.vue";

@Component({
  components: {
    LItem,
    FButton,
  }
})
export default class Main extends Mixins(mixinPart) {
  @Prop() dependant_num: number;
  created() {
    this._title = 'how_many_dependants';
  }

  addDependant() {
    this.$emit('addDependant', true);
  }
}
