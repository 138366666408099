
import { Component, Vue } from 'vue-property-decorator';
import EmployeedemandMain from "@/components/employeedemand/Main.vue";

@Component({
  components: {
    EmployeedemandMain,
  }
})
export default class EmployeeEach extends Vue {
}
