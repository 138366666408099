
  import { Component, Prop, Vue } from "vue-property-decorator";
  import { Steps } from "ant-design-vue";
  import FButton from "@/components/f/Button.vue";

  const { Step } = Steps;

  @Component({
    components: {
      FButton,
      Steps,
      Step,
    },
  })
  export default class Wizard extends Vue {
    @Prop() value: any;
    @Prop() steps: {
      [key: string]: any;
    };

    get step() {
      return this.value;
    }
    set step(val) {
      this.$emit("input", val);
    }

    getStatus(step, index) {
      if (step > index) return "finish";
      else if (step == index) return "current";
      else if (step < index) return "wait";
    }

    clickPrev() {
      this.$emit("prev", true);
    }
    clickNext() {
      this.$emit("next", true);
    }

    get step_hash() {
      const result = {};
      Object.keys(this.steps).forEach((key) => {
        result[this.steps[key].index] = key;
      });
      return result;
    }

    get last_key(): string {
      let result = null;
      Object.keys(this.steps).forEach((key) => {
        if (this.steps[key].is_header === undefined || this.steps[key].is_header === true) {
          result = key;
        }
      });
      return result;
    }
  }
