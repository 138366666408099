
  import { Component, Mixins, Watch } from "vue-property-decorator";
  import mixinDemandEmployee from "@/mixins/mixinDemandEmployee";
  import validate from "@/validations/validate";
  import {
    validationEmployeeRoute,
    validationEmployeeStep1,
    validationEmployeeStep2,
    validationEmployeeStep3,
    validationEmployeeStep4,
  } from "@/validations/employee";
  import validate_api from "@/validations/validate_api";
  import * as util from "@/util";
  import { validationDependant, validationSpouce } from "@/validations/family";
  import { Provide } from "@/library/provide";
  import LProgress from "@/components/l/Progress.vue";
  import LWizard from "@/components/l/Wizard.vue";
  import LBread from "@/components/l/Bread.vue";
  import EmployeedemandBase from "@/components/employeedemand/Base.vue";
  import EmployeejoinAddress from "@/components/employeejoin/Address.vue";
  import EmployeejoinBank from "@/components/employeejoin/Bank.vue";
  import EmployeejoinTax from "@/components/employeejoin/Tax.vue";
  import EmployeejoinSpouce from "@/components/employeejoin/Spouce.vue";
  import EmployeejoinDependants from "@/components/employeejoin/Dependants.vue";
  import EmployeejoinEmployeeinfo from "@/components/employeejoin/Employeeinfo.vue";
  import LCard from "@/components/l/Card.vue";
  import FButton from "@/components/f/Button.vue";
  import EmployeejoinDependantsdisp from "@/components/employeejoin/Dependantsdisp.vue";

  @Component({
    components: {
      LProgress,
      LWizard,
      LBread,
      EmployeedemandBase,
      EmployeejoinAddress,
      EmployeejoinBank,
      EmployeejoinTax,
      EmployeejoinSpouce,
      EmployeejoinDependants,
      EmployeejoinEmployeeinfo,
      LCard,
      FButton,
      EmployeejoinDependantsdisp,
    },
  })
  export default class Main extends Mixins(mixinDemandEmployee) {
    created() {
      this.m.initEditManager({ name: "join" });
      if (this.fetched) {
        this.fetchedData(this.fetched);
      }
    }

    @Watch("fetched")
    fetchedData(fetched) {
      if (fetched) {
        this.m.initState({ name: "step" });
        this.m.setState({ name: "step", val: this.steps["confirm"].index });
        this.m.edit_manager.join.edit({
          employee: this.employeely.employee,
          spouce: this.family.spouce ?? this.add_spouce_object,
          dependants: Object.values(this.family.dependants),
          dependant_num: Object.keys(this.family.dependants).length,
          each_employee_infos: this.info.each_employee_infos,
        });
        this.employee.carfare_type = this.employee.carfare_type == 0 ? 0 : 1;
      }
    }

    get add_spouce_object() {
      return {
        kana: null,
        name: null,
        birth_date: null,
        mynum: null,
        is_housemate: 1,
        disability_type: 0,
        income: 0,
        retire_income: 0,
        other_income: 0,
        is_not_live: false,
        address: null,
        not_live_money: null,
        is_special_disability_housemate: 1,
        disability_reason: null,
      };
    }

    get fetched() {
      return !!this.global && !!this.employeely && !!this.family && !!this.info;
    }

    get employee() {
      return this.m.edit_manager.join.object.employee;
    }
    get dependants() {
      return this.m.edit_manager.join.object.dependants;
    }
    get spouce() {
      return this.m.edit_manager.join.object.spouce;
    }
    get dependant_num() {
      return this.m.edit_manager.join.object.dependant_num;
    }

    async clickNext() {
      if (this.step_key == "initial") {
        this.m.setState({ name: "step", val: this.steps[this.steps["initial"].next_index].index });
      } else if (this.step_key == "base") {
        if (validate(validationEmployeeStep1(this.employee, false))) {
          const res = await util.post("employee_each/confirm_mail", {
            mail: this.employee.mail,
            employee_id: this.employee_id,
          });
          if (validate_api(res, ["使用できないメールアドレスが入力されています。"])) {
            this.m.setState({ name: "step", val: this.steps[this.steps["base"].next_index].index });
          }
        }
      } else if (this.step_key == "address") {
        let error = validationEmployeeStep2(this.employee);
        error = error.concat(validationEmployeeRoute(this.employee));
        if (validate(error)) {
          this.m.setState({
            name: "step",
            val: this.steps[this.steps["address"].next_index].index,
          });
        }
      } else if (this.step_key == "bank") {
        if (validate(validationEmployeeStep3(this.employee))) {
          this.m.setState({ name: "step", val: this.steps[this.steps["bank"].next_index].index });
        }
      } else if (this.step_key == "tax") {
        if (validate(validationEmployeeStep4(this.employee, true))) {
          if (this.employee.tax_type == 0) {
            this.m.setState({
              name: "step",
              val: this.steps[this.steps["tax"].next_kou_index].index,
            });
          } else {
            this.m.setState({
              name: "step",
              val: this.steps[this.steps["tax"].next_otsu_index].index,
            });
          }
        }
      } else if (this.step_key == "spouce") {
        if (
          this.employee.is_spouce == 0 ||
          this.employee.is_spouce_deduction == 0 ||
          validate(validationSpouce(null, { spouce: this.spouce, index: true }))
        ) {
          this.m.setState({ name: "step", val: this.steps[this.steps["spouce"].next_index].index });
        }
      } else if (this.step_key == "dependant") {
        let error = [];
        if (this.dependant_num == 1) {
          error = error.concat(
            validationDependant(null, {
              dependant: this.dependants[0],
              is_spouce: this.employee.is_spouce == 1,
              index: 0,
            }),
          );
        } else {
          for (let index = 0; index < this.dependant_num; index++) {
            error = error.concat(
              validationDependant(null, {
                dependant: this.dependants[index],
                is_spouce: this.employee.is_spouce == 1,
                index: index + 1,
              }),
            );
          }
        }
        if (validate(error)) {
          this.m.setState({
            name: "step",
            val: this.steps[this.steps["dependant"].next_index].index,
          });
        }
      } else if (this.step_key == "employee_info") {
        this.m.setState({
          name: "step",
          val: this.steps[this.steps["employee_info"].next_index].index,
        });
      } else if (this.step_key == "confirm") {
        this.m.create({
          name: "demand",
          args: {
            employee: this.m.edit_manager.join.object.employee,
            spouce: this.m.edit_manager.join.object.spouce,
            dependants: this.m.edit_manager.join.object.dependants,
            each_employee_infos: this.m.edit_manager.join.object.each_employee_infos,
            employee_id: this.employee.id,
            is_individual_unit: this.global.config.is_individual_unit,
            individual_units: this.global.individual_units,
          },
        });
      }
    }
    clickPrev() {
      if (this.step_key == "base") {
        this.m.setState({ name: "step", val: this.steps[this.steps["base"].prev_index].index });
      } else if (this.step_key == "address") {
        this.m.setState({ name: "step", val: this.steps[this.steps["address"].prev_index].index });
      } else if (this.step_key == "bank") {
        this.m.setState({ name: "step", val: this.steps[this.steps["bank"].prev_index].index });
      } else if (this.step_key == "tax") {
        this.m.setState({ name: "step", val: this.steps[this.steps["tax"].prev_index].index });
      } else if (this.step_key == "spouce") {
        this.m.setState({ name: "step", val: this.steps[this.steps["spouce"].prev_index].index });
      } else if (this.step_key == "dependant") {
        this.m.setState({
          name: "step",
          val: this.steps[this.steps["dependant"].prev_index].index,
        });
      } else if (this.step_key == "employee_info") {
        if (this.employee.tax_type == 0) {
          this.m.setState({
            name: "step",
            val: this.steps[this.steps["employee_info"].prev_kou_index].index,
          });
        } else {
          this.m.setState({
            name: "step",
            val: this.steps[this.steps["employee_info"].prev_otsu_index].index,
          });
        }
      } else if (this.step_key == "confirm") {
        if (this.employee.tax_type == 0) {
          this.m.setState({
            name: "step",
            val: this.steps[this.steps["confirm"].prev_kou_index].index,
          });
        } else {
          this.m.setState({
            name: "step",
            val: this.steps[this.steps["confirm"].prev_otsu_index].index,
          });
        }
      }
    }

    @Provide("is_edit")
    get is_edit() {
      return this.step < this.steps["confirm"].index;
    }
    @Provide("is_add")
    get is_add() {
      return false;
    }
    @Provide("force_sp")
    get force_sp() {
      return true;
    }
  }
