
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FText from "@/components/f/Text.vue";

@Component({
  components: {
    LItem,
    FText,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'mycar_km';
    this._need = true;
  }

  get condition() {
    return this.object.carfare_type != 0 && this.object.is_car;
  }

  get is_change() {
    return this.object.km !== this.old_object.km;
  }
}
