
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FTextarea from "@/components/f/Textarea.vue";

@Component({
  components: {
    LItem,
    FTextarea,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'school_and_year';
    this._need = true;
  }

  get placeholder() {
    return '・' + this.$t('arrow_university') + '\n' + '・' + this.$t('enter_school_date');
  }
  
  get condition() {
    return this.object.is_work_student == 1;
  }

  get is_change() {
    return this.object.work_student_reason !== this.old_object.work_student_reason;
  }
}
