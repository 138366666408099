
  import { Component, Mixins, Prop } from "vue-property-decorator";
  import mixinPage from "@/mixins/mixinPage";
  import { Provide } from "@/library/provide";
  import EmployeeeachSelfFamilyDependantName from "@/components/employeeeach/self/family/dependant/Name.vue";
  import EmployeeeachSelfFamilyDependantBirthdate from "@/components/employeeeach/self/family/dependant/Birthdate.vue";
  import EmployeeeachSelfFamilyDependantMynum from "@/components/employeeeach/self/family/dependant/Mynum.vue";
  import EmployeeeachSelfFamilyDependantRelationship from "@/components/employeeeach/self/family/dependant/Relationship.vue";
  import EmployeeeachSelfFamilyDependantHousemate from "@/components/employeeeach/self/family/dependant/Housemate.vue";
  import EmployeeeachSelfFamilyDependantAddress from "@/components/employeeeach/self/family/dependant/Address.vue";
  import EmployeeeachSelfFamilyDependantNotresident from "@/components/employeeeach/self/family/dependant/Notresident.vue";
  import EmployeeeachSelfFamilyDependantRemittance from "@/components/employeeeach/self/family/dependant/Remittance.vue";
  import EmployeeeachSelfFamilyDependantIncome from "@/components/employeeeach/self/family/dependant/Income.vue";
  import EmployeeeachSelfFamilyDependantDisability from "@/components/employeeeach/self/family/dependant/Disability.vue";
  import EmployeeeachSelfFamilyDependantDisabilityreason from "@/components/employeeeach/self/family/dependant/Disabilityreason.vue";
  import EmployeeeachSelfFamilyDependantHousematelivelihood from "@/components/employeeeach/self/family/dependant/Housematelivelihood.vue";
  import EmployeeeachSelfFamilyDependantInternationalstudent from "@/components/employeeeach/self/family/dependant/Internationalstudent.vue";

  @Component({
    components: {
      EmployeeeachSelfFamilyDependantName,
      EmployeeeachSelfFamilyDependantBirthdate,
      EmployeeeachSelfFamilyDependantMynum,
      EmployeeeachSelfFamilyDependantRelationship,
      EmployeeeachSelfFamilyDependantHousemate,
      EmployeeeachSelfFamilyDependantAddress,
      EmployeeeachSelfFamilyDependantNotresident,
      EmployeeeachSelfFamilyDependantRemittance,
      EmployeeeachSelfFamilyDependantIncome,
      EmployeeeachSelfFamilyDependantDisability,
      EmployeeeachSelfFamilyDependantDisabilityreason,
      EmployeeeachSelfFamilyDependantHousematelivelihood,
      EmployeeeachSelfFamilyDependantInternationalstudent,
    },
  })
  export default class Main extends Mixins(mixinPage) {
    @Prop() dependant: any;
    @Prop() index: number | string;
    @Prop() is_spouce: boolean;
    @Prop({ default: false }) is_nencho: boolean;

    get raw_object() {
      return this.dependant;
    }
    get tmp_object() {
      return this.dependant;
    }
  }
