
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import mixinForm from '@/mixins/mixinForm';
import FIcon from "@/components/f/Icon.vue";
import LDialog from "@/components/l/Dialog.vue";
import LContent from "@/components/l/Content.vue";
import LBread from "@/components/l/Bread.vue";
import FButton from "@/components/f/Button.vue";
import LProgress from "@/components/l/Progress.vue";


@Component({
  components: {
    FIcon,
    LDialog,
    LContent,
    LBread,
    FButton,
    LProgress,
  }
})
export default class Text extends Mixins(mixinForm) {
  @Prop() is_display: boolean;
  @Prop() width: number;//pxをnumberのみ
  @Prop() height: number;//pxをnumberのみ
  @Prop({default: 4}) quality: number;
  @Prop() bg_icon: any;
  @Prop() just: boolean;
  //アップロードする際に使用するオブジェクトの要素(face_blobなど)をv-modelでセットすると、blob化されたものが@inputで返る

  loading_image: boolean = false;

  rotate_right() {
    this.loading_image = true;
    setTimeout(() => {
      this.croppa_object.rotate();
      this.loading_image = false; 
    }, 1);
  }
  rotate_left() {
    this.loading_image = true; 
    setTimeout(() => {
      this.croppa_object.rotate(-1);
      this.loading_image = false; 
    }, 1);
  }

  @Watch('use_is_display')
  onChangeUseIsDisplay(val, oldval) {
    if (!oldval && val) {
      this.removeImage();
    }
  }

  removeImage() {
    if (this.hasImage) {
      this.croppa_object.remove();
    }
  }

  get hasImage() {
    return this.croppa_object.imageSet;
  }

  get use_is_display() {
    return this.is_display;
  }
  set use_is_display(val) {
    this.$emit('updateIsDisplay', val);
  }

  croppa_object: any = {};


  get style() {
    return {
      '--width': this.width+'px',
      '--height': this.height+'px',
    };
  }

  updateImage() {
    if (this.hasImage) {
      this.$emit('updateImage', this.croppa_object.generateDataUrl('image/jpeg'));
    } else {
      this.$emit('updateImage', null);
    }
  }
}
