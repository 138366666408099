
import { Component, Mixins } from 'vue-property-decorator';
import mixinPart from '@/mixins/mixinPart';
import LItem from "@/components/l/Item.vue";
import FRadio from "@/components/f/Radio.vue";

@Component({
  components: {
    LItem,
    FRadio,
  }
})
export default class Main extends Mixins(mixinPart) {
  created() {
    this._title = 'housemate_with_livelihood';
    this._need = true;
  }

  get condition() {
    return this.object.disability_type == 2 && this.object.is_housemate == 0;
  }

  get is_change() {
    return this.object.is_special_disability_housemate !== this.old_object.is_special_disability_housemate;
  }
}
