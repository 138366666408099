
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FText from "@/components/f/Text.vue";

@Component({
  components: {
    LItem,
    FText,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'householder_name';
    this._need = true;
  }

  get is_change() {
    return this.object.householder_relationship_id !== this.old_object.householder_relationship_id || 
      this.object.householder_name !== this.old_object.householder_name;
  }
}
