
import { Component, Mixins } from 'vue-property-decorator';
import mixinPart from '@/mixins/mixinPart';
import LItem from "@/components/l/Item.vue";
import FRadio from "@/components/f/Radio.vue";

@Component({
  components: {
    LItem,
    FRadio,
  }
})
export default class Main extends Mixins(mixinPart) {
  created() {
    this._title = 'disability';
    this._need = true;
    this._popup_title = 'disability';
    this._popup_content = 'popup.disability';
  }

  get is_change() {
    return this.object.disability_type !== this.old_object.disability_type;
  }
}
