
import { Component, Mixins, Prop } from 'vue-property-decorator';
import mixinPart from '@/mixins/mixinPart';
import { Inject } from '@/library/provide';
import LItem from "@/components/l/Item.vue";
import FRadio from "@/components/f/Radio.vue";

@Component({
  components: {
    LItem,
    FRadio,
  }
})
export default class Main extends Mixins(mixinPart) {
  created() {
    this._title = 'is_spouce_either';
    this._need = true;
  }

  get condition() {
    return !!this.is_edit;
  }

  get is_change() {
    return this.object.is_spouce_deduction !== this.old_object.is_spouce_deduction;
  }
}
