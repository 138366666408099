
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FSelect from "@/components/f/Select.vue";
import FText from "@/components/f/Text.vue";

@Component({
  components: {
    LItem,
    FSelect,
    FText,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'householder_relationship';
    this._need = true;
    this._info = 'info.from_you_householder_relationship';
  }

  get is_change() {
    return this.object.householder_relationship_id !== this.old_object.householder_relationship_id || 
      (this.object.householder_relationship_id == 6 && this.object.householder_relationship_custom !== this.old_object.householder_relationship_custom);
  }
}
