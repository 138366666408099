
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FCheck from "@/components/f/Check.vue";

@Component({
  components: {
    LItem,
    FCheck,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'carfare_way';
    this._info = 'info.carfare_way';
    this._need = true;
  }

  get condition() {
    return this.object.carfare_type != 0;
  }

  get carfare_way_keys() {
    return {
      0: 'is_train',
      1: 'is_car',
    };
  }

  get is_change() {
    return this.object.is_train !== this.old_object.is_train || 
      this.object.is_car !== this.old_object.is_car;
  }
}
