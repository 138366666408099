
import { Component, Mixins, Prop } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FText from "@/components/f/Text.vue";

@Component({
  components: {
    LItem,
    FText,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  @Prop() loading: boolean;
  created() {
    this._title = 'address';
    this._need = true;
    this.auth_shift = false;
    this.auth_attendance = false;
  }

  get is_change() {
    return this.object.address !== this.old_object.address;
  }
}
