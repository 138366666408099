
import { Component, Mixins, Vue, Prop } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import EmployeejoinEmployeeinfoRow from "@/components/employeejoin/employeeinfo/Row.vue";
import EmployeejoinEmployeeinfoImagerow from "@/components/employeejoin/employeeinfo/Imagerow.vue";

@Component({
  components: {
    EmployeejoinEmployeeinfoRow,
    EmployeejoinEmployeeinfoImagerow,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() employee_infos: any;

  get each_employee_infos() {
    return this.manager.object.each_employee_infos;
  }
}
