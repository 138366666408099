
import { Component, Mixins, Prop } from 'vue-property-decorator';
import mixinPart from '@/mixins/mixinPart';
import LItem from "@/components/l/Item.vue";
import FText from "@/components/f/Text.vue";

@Component({
  components: {
    LItem,
    FText,
  }
})
export default class Main extends Mixins(mixinPart) {
  created() {
    this._title = 'address';
    this._need = true;
  }

  get is_change() {
    return this.object.address !== this.old_object.address;
  }
}
