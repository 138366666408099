import { render, staticRenderFns } from "./Imagerow.vue?vue&type=template&id=0af8a7d9&scoped=true&lang=pug"
import script from "./Imagerow.vue?vue&type=script&lang=ts"
export * from "./Imagerow.vue?vue&type=script&lang=ts"
import style0 from "./Imagerow.vue?vue&type=style&index=0&id=0af8a7d9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0af8a7d9",
  null
  
)

export default component.exports