
import { Inject } from '@/library/provide';
import mixinPage from '@/mixins/mixinPage';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import LItemlevel from "@/components/l/Itemlevel.vue";
import FText from "@/components/f/Text.vue";

@Component({
  components: {
    LItemlevel,
    FText,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() employee_info: any;
  @Prop() value: any;

  created() {
    this.input_each_employee_info();
  }
  
  get raw_object() {
    return this.value.filter((each_employee_info, i) => each_employee_info.freehead !== null && each_employee_info.content !== null);
  }
  get tmp_object() {
    return this.value
  }
  set tmp_object(val) {
    this.$emit('input', val);
  }
 

  input_each_employee_info() {
    let last_index = this.object.length - 1;
    if (last_index == -1) {
      this.object.push({freehead: null, content: null});
    } else if (this.object[last_index].freehead !== null && this.object[last_index].content !== null) {
      this.object.push({freehead: null, content: null});
    }

    last_index = this.object.length - 1;

    this.object = this.object.filter((each_object, i) => i == last_index || each_object.freehead !== null || each_object.content !== null);
  }
}
