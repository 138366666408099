
  import { Component, Mixins } from "vue-property-decorator";
  import mixinPart from "@/mixins/mixinPart";
  import { Inject } from "@/library/provide";
  import LItem from "@/components/l/Item.vue";
  import FDate from "@/components/f/Date.vue";

  @Component({
    components: {
      LItem,
      FDate,
    },
  })
  export default class Main extends Mixins(mixinPart) {
    created() {
      this._title = "birth_date";
      this._need = true;
    }

    get is_change() {
      return this.object.birth_date !== this.old_object.birth_date;
    }
  }
