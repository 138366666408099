
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FTextarea from "@/components/f/Textarea.vue";

@Component({
  components: {
    LItem,
    FTextarea,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'disability_reason';
    this._need = true;
  }

  get placeholder() {
    if (this.object.disability_type == 1)
      return '・' + this.$t('disability_reason_placeholder_book') + '\n' + '・' + this.$t('disability_reason_placeholder_level6');
    else if (this.object.disability_type == 2)
      return '・' + this.$t('disability_reason_placeholder_book') + '\n' + '・' + this.$t('disability_reason_placeholder_level1');
  }

  get condition() {
    return this.object.disability_type != 0;
  }

  get is_change() {
    return this.object.disability_reason !== this.old_object.disability_reason;
  }
}
