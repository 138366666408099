
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FRadio from "@/components/f/Radio.vue";

@Component({
  components: {
    LItem,
    FRadio,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'carfare_type';
    this._need = true;
  }

  get is_change() {
    return (this.object.carfare_type != 0) !== (this.old_object.carfare_type != 0);
  }
}
