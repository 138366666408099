
import { Component, Mixins } from 'vue-property-decorator';
import mixinPart from '@/mixins/mixinPart';
import LItem from "@/components/l/Item.vue";
import FName from "@/components/f/Name.vue";

@Component({
  components: {
    LItem,
    FName,
  }
})
export default class Main extends Mixins(mixinPart) {
  created() {
    this._title = 'name';
    this._need = true;
  }

  get is_change() {
    return this.object.name !== this.old_object.name ||
      this.object.kana !== this.old_object.kana;
  }
}
