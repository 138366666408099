
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FTextarea from "@/components/f/Textarea.vue";

@Component({
  components: {
    LItem,
    FTextarea,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'route';
  }

  get placeholder() {
    if (this.object.is_train && !this.object.is_car)
      return this.$t('route_placeholder_tojo') + ',　\n' + this.$t('route_placeholder_fukutoshin');
    else if (!this.object.is_train && this.object.is_car)
      return this.$t('route_placeholder_localroad') + ',　\n' + this.$t('route_placeholder_highway');
    else if (this.object.is_train && this.object.is_car)
      return this.$t('route_placeholder_bike') + ',　\n' + this.$t('route_placeholder_tojo') + ',　\n' + this.$t('route_placeholder_fukutoshin');
  }

  get condition() {
    return this.object.carfare_type != 0 && (this.object.is_train || this.object.is_car);
  }

  get is_change() {
    return this.object.route !== this.old_object.route;
  }
}
