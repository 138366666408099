
import { Component, Mixins, Prop } from 'vue-property-decorator';
import mixinPart from '@/mixins/mixinPart';
import LItem from "@/components/l/Item.vue";
import FRadio from "@/components/f/Radio.vue";
import * as util from '@/util';

@Component({
  components: {
    LItem,
    FRadio,
  }
})
export default class Main extends Mixins(mixinPart) {
  @Prop({default: util.obj2year()}) year: number;

  created() {
    this._title = 'international_student';
    this._need = true;
  }

  get condition() {
    return this.object.is_not_live == 1 && this.is_target;
  }

  get is_change() {
    return this.object.is_international_student !== this.old_object.is_international_student;
  }

  get is_target() {
    return util.age(this.object.birth_date, this.year+"-01-01") >= 30 && util.age(this.object.birth_date, this.year+"-01-01") < 70;
  }
}
