
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FZip from "@/components/f/Zip.vue";

@Component({
  components: {
    LItem,
    FZip,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'zip';
    this._need = true;
    this.auth_shift = false;
    this.auth_attendance = false;
  }
  setLoading(val) {
    this.$emit('loading', val);
  }

  get is_change() {
    return this.object.zip !== this.old_object.zip;
  }
}
